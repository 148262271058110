<template>
  <right-dialog
    :model-value="dialog"
    :title="
      $t('account.organisation.menu.title', {
        organisation: props.organisation.name,
      })
    "
    @update:model-value="$emit('update:dialog', $event)"
  >
    <q-list class="item-rounded">
      <q-item
        v-for="link in menuLinks"
        :key="link.id"
        v-ripple
        clickable
        class="border q-mb-sm"
        :data-test="link.id"
        :to="link.to"
        :href="link.href"
      >
        <q-item-section avatar>
          <q-icon :name="link.icon" size="xs" />
        </q-item-section>

        <q-item-section>
          {{ link.label() }}
        </q-item-section>
        <q-item-section side>
          <q-icon name="fa-solid fa-chevron-right" color="grey" size="xs" />
        </q-item-section>
      </q-item>
    </q-list>
  </right-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useCommonMenu } from 'composables/common/ui/useCommonMenu'

import RightDialog from 'components/common/ui/dialog/RightDialog.vue'

import type {
  BaseLinks,
  LinkActions,
  OrganisationLight,
} from 'types/common/layout/layout'

export type LinkIds =
  | 'organisation-basicInfo'
  | 'organisation-billing'
  | 'organisation-members'
  | 'organisation-roles'
  | 'organisation-page'

// PROPS
const props = defineProps<{
  dialog: boolean
  organisation: OrganisationLight
  linkActions: LinkActions<LinkIds>
  checkRoles: (roles: string[], all?: boolean) => boolean
}>()
// EMITS
defineEmits<{
  'update:dialog': [value: boolean]
}>()

const { t } = useI18n()

// LINKS
const organisationLinks: BaseLinks<LinkIds>[] = [
  {
    id: 'organisation-basicInfo',
    label: () => t('account.organisation.update.label'),
    icon: 'fa-solid fa-pen-to-square',
    access: ['ORGANISATION_UPDATE_SETTINGS'],
  },
  {
    id: 'organisation-billing',
    label: () => t('account.organisation.billing.title'),
    icon: 'fa-solid fa-receipt',
    access: ['ORGANISATION_UPDATE_SETTINGS', 'ORGANISATION_ACCOUNTING'],
  },
  {
    id: 'organisation-members',
    label: () => t('account.organisation.members.label'),
    icon: 'fa-solid fa-users',
    access: ['ORGANISATION_MEMBERS'],
  },
  {
    id: 'organisation-roles',
    label: () => t('account.organisation.roles.label'),
    icon: 'fa-solid fa-key',
    access: ['ORGANISATION_ROLES'],
  },
  {
    id: 'organisation-page',
    label: () => t('account.organisation.page.label'),
    icon: 'fa-solid fa-paintbrush',
    access: ['ORGANISATION_UPDATE_SETTINGS'],
  },
]

const { menuLinks } = useCommonMenu<LinkIds>(
  organisationLinks,
  props.linkActions,
  props.checkRoles
)
</script>
