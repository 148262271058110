<template>
  <q-btn
    :label="initials"
    class="q-mx-sm"
    color="primary"
    :outline="menu"
    round
  >
    <q-tooltip>
      {{ $t('router.profile') }}
    </q-tooltip>

    <q-menu
      v-model="menu"
      anchor="bottom right"
      self="top right"
      :offset="[0, 24]"
      transition-show="jump-down"
      transition-hide="jump-up"
    >
      <div class="q-pa-lg">
        <div class="row q-col-gutter-md">
          <div class="col-auto">
            <q-avatar
              size="70px"
              :class="{
                'border border-primary primary-light-bg text-primary':
                  !userIdentification.pictureUrl,
              }"
            >
              <q-img
                v-if="userIdentification.pictureUrl"
                :src="userIdentification.pictureUrl"
              />
              <span v-else class="text-body2">
                {{ initials }}
              </span>
            </q-avatar>
          </div>
          <div class="col column justify-center">
            <p
              class="text-body1 text-weight-medium"
              :class="{
                invisible:
                  !userIdentification.firstname || !userIdentification.lastname,
              }"
            >
              <span>
                {{ userIdentification.firstname || initials }}
              </span>
              <span v-if="userIdentification.lastname" class="q-ml-xs">
                {{ userIdentification.lastname[0] }}.
              </span>
            </p>
            <p class="text-caption">{{ userIdentification.email }}</p>
          </div>
        </div>

        <q-separator class="q-mt-lg q-mb-sm" />

        <q-list class="item-rounded">
          <template v-for="link in menuLinks" :key="link.id">
            <link-item :menu-link="link" dense :data-test="link.id" />
            <q-separator spaced />
          </template>
          <!-- Log out -->
          <q-item
            v-ripple
            clickable
            dense
            class="text-negative"
            data-test="logout"
            @click="onLogout"
          >
            <q-item-section side>
              <q-icon name="fa-solid fa-arrow-right-from-bracket" size="xs" />
            </q-item-section>

            <q-item-section>{{ $t('common.signOut') }}</q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useTextUtils } from 'composables/common/utils/useTextUtils'
import { useCommonMenu } from 'composables/common/ui/useCommonMenu'

import LinkItem from 'components/common/nav/LinkItem.vue'

import { BaseLinks, LinkActions } from 'types/common/layout/layout'

type LinkIds = 'profile' | 'organisations' | 'billing'

// PROPS
const props = defineProps<{
  linkActions: LinkActions<LinkIds>
  checkRoles: (roles: string[], all?: boolean) => boolean
  onLogout: () => void
  userIdentification: {
    email: string
    firstname: string | null
    lastname: string | null
    pictureUrl: string | null
  }
}>()

const { t } = useI18n()
const { getInitials } = useTextUtils()

// BTN
const initials = computed(() => {
  return getInitials(
    props.userIdentification.email,
    props.userIdentification.firstname,
    props.userIdentification.lastname
  )
})

// MENU
const menu = ref(false)

const accountLinks: BaseLinks<LinkIds>[] = [
  {
    id: 'profile',
    label: () => t('account.personalInfo.label'),
    icon: 'fa-solid fa-user',
  },
  {
    id: 'organisations',
    label: () => t('account.organisation.manage'),
    icon: 'fa-solid fa-suitcase',
  },
  {
    id: 'billing',
    label: () => t('account.organisation.billing.title'),
    icon: 'fa-solid fa-receipt',
    access: ['ORGANISATION_UPDATE_SETTINGS', 'ORGANISATION_ACCOUNTING'],
  },
]

const { menuLinks } = useCommonMenu<LinkIds>(
  accountLinks,
  props.linkActions,
  props.checkRoles
)
</script>
