<template>
  <q-btn
    class="text-weight-medium"
    :label="locale.toUpperCase()"
    :color="menu ? 'primary' : 'muted'"
    round
    outline
  >
    <q-tooltip>
      {{ $t('common.language') }}
    </q-tooltip>

    <q-menu
      v-model="menu"
      :anchor="anchor || 'bottom right'"
      :self="self || 'top right'"
      :offset="offset || [0, 24]"
    >
      <div class="q-pa-lg">
        <h4 class="q-mb-md q-mt-none">
          {{ $t('common.language', 2) }}
        </h4>
        <q-list style="margin-left: -8px" class="item-rounded">
          <q-item
            v-for="lang in countryList"
            :key="lang.value"
            v-close-popup
            clickable
            dense
            class="q-mt-sm q-px-sm q-py-xs"
            :active="locale === lang.locale"
            @click="handleLocaleChange(lang.value)"
          >
            <q-item-section side>
              <q-no-ssr>
                <circle-flags
                  :country="lang.flag"
                  style="height: 24px; width: 24px"
                />
              </q-no-ssr>
            </q-item-section>
            <q-item-section>
              {{ lang.label }} ({{ lang.value.toUpperCase() }})
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useChangeLocale } from 'composables/common/i18n/useChangeLocale'

// TODO: Check when vue-flagpack is working with vue 3 :
// https://github.com/Yummygum/vue-flagpack
import CircleFlags from 'components/common/ui/flags/CircleFlags.vue'

import type { LocalesCode } from 'src/i18n/locales'
import type { TCountryCodes } from 'components/common/ui/flags/flagTypes'
import type { QMenu } from 'quasar'

// PROPS
defineProps<{
  anchor?: QMenu['anchor']
  self?: QMenu['self']
  offset?: QMenu['offset']
}>()
// EMITS
const emits = defineEmits<{
  changeLocale: [code: LocalesCode]
}>()

const { changeLocale } = useChangeLocale()
const { locale } = useI18n({ useScope: 'global' })

const menu = ref(false)

const currentLocale = computed(() => {
  return countryList.find((el) => el.locale === locale.value)
})

type Locale = {
  locale: string
  value: LocalesCode
  label: string
  flag: TCountryCodes
}

const countryList: Locale[] = [
  {
    locale: 'fr',
    value: 'fr',
    label: 'Français',
    flag: 'fr',
  },
  {
    locale: 'en',
    value: 'en',
    label: 'English',
    flag: 'gb',
  },
]

const handleLocaleChange = (code: LocalesCode) => {
  if (code !== currentLocale.value?.value) {
    changeLocale(code)

    emits('changeLocale', code)
  }
}
</script>

<style scoped lang="scss">
:deep(.normal-flag) {
  margin-bottom: 0;
}
</style>
