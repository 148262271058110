<template>
  <q-btn
    class="q-mx-sm text-weight-medium"
    :color="menu ? 'primary' : 'muted'"
    outline
    round
  >
    <q-icon name="fa-solid fa-gear" size="16px" />
    <q-tooltip>
      {{ $t('admin.label') }}
    </q-tooltip>

    <q-menu
      v-model="menu"
      anchor="bottom right"
      self="top right"
      :offset="[0, 24]"
      transition-show="jump-down"
      transition-hide="jump-up"
    >
      <div class="q-pa-lg">
        <h4 class="q-mb-md q-mt-none">
          {{ $t('admin.label') }}
        </h4>
        <q-list class="item-rounded">
          <template v-for="(link, i) in menuLinks" :key="link.id">
            <q-separator v-if="i !== 0" spaced />
            <link-item :menu-link="link" dense />
          </template>
        </q-list>
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useCommonMenu } from 'composables/common/ui/useCommonMenu'

import LinkItem from 'components/common/nav/LinkItem.vue'

import { BaseLinks, LinkActions } from 'types/common/layout/layout'

type LinkIds =
  | 'users'
  | 'roles'
  | 'services'
  | 'allowance'
  | 'purchases'
  | 'function'
  | 'impersonate'
  | 'import-export'

const props = defineProps<{
  linkActions: LinkActions<LinkIds>
  checkRoles: (roles: string[], all?: boolean) => boolean
}>()

const { t } = useI18n()

const menu = ref(false)

const adminLinks: BaseLinks<LinkIds>[] = [
  {
    id: 'users',
    label: () => t('admin.users.admins.linkLabel'),
    icon: 'fa-solid fa-users',
    access: ['ROLE_ADMIN', 'ROLE_ROOT'],
  },
  {
    id: 'roles',
    label: () => t('admin.users.roles.linkLabel'),
    icon: 'fa-solid fa-key',
    access: ['ROLE_ADMIN', 'ADMIN_MANAGE_ROLES'],
  },
  {
    id: 'services',
    label: () => t('admin.billing.services.linkLabel', 2),
    icon: 'fa-solid fa-file-invoice',
    access: ['ROLE_ADMIN', 'ADMIN_MANAGE_SERVICES'],
  },
  {
    id: 'allowance',
    label: () => t('admin.billing.allowance.title'),
    icon: 'fa-solid fa-lock-open',
    access: ['ROLE_ADMIN', 'ADMIN_MANAGE_SERVICES'],
  },
  {
    id: 'purchases',
    label: () => t('admin.purchases.menu'),
    icon: 'fa-solid fa-cart-shopping',
    access: ['ROLE_ADMIN', 'ADMIN_MANAGE_BILLING'],
  },
  {
    id: 'function',
    label: () => t('admin.operational.functions.linkLabel'),
    icon: 'fa-solid fa-function',
    access: ['ROLE_ADMIN', 'ADMIN_MANAGE_FUNCTIONS'],
  },
  {
    id: 'impersonate',
    label: () => t('admin.impersonate.menu'),
    icon: 'fa-solid fa-user-secret',
    oneOfAccess: ['ADMIN_IMPERSONATE_USER', 'ADMIN_IMPERSONATE_CUSTOMER'],
  },
  {
    id: 'import-export',
    label: () => t('receiver.admin.importExport.pageTitle'),
    icon: 'fa-solid fa-truck-arrow-right',
    oneOfAccess: ['ADMIN_MANAGE_FUNCTIONS', 'ADMIN_IMPERSONATE_ORGANISATION'],
  },
]

const { menuLinks } = useCommonMenu<LinkIds>(
  adminLinks,
  props.linkActions,
  props.checkRoles
)
</script>
