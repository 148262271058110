export const accountLinks = {
  profile: {
    to: '/account/profile',
  },
  organisations: {
    to: '/account/organisations',
  },
  billing: {
    to: '/account/billing/subscription',
  },
}

export const organisationLinks = {
  'organisation-basicInfo': {
    to: '/account/organisations/info',
  },
  'organisation-billing': {
    to: '/account/billing/subscription',
  },
  'organisation-members': {
    to: '/account/organisations/members',
  },
  'organisation-roles': {
    to: '/account/organisations/roles',
  },
  'organisation-page': {
    to: '/account/organisations/page',
  },
}
